
import { defineComponent } from "vue";
import CustomTableTopFilter from "@/components/customTables/CustomTableTopFilter.vue";
import { round } from "@amcharts/amcharts4/.internal/core/utils/Math";

/* eslint-disable */
export default defineComponent({
  name: "inDebtTable",
  data() {
    return {};
  },
  components: {
    CustomTableTopFilter,
  },
  props: {
    widgetClasses: String,
    tableName: String,
    list: Array,
    showStatus: {
      type: Boolean,
      default: true,
    },
    pageProp: Number,
    filterStatus: Array,
    pages: Number,
    showFilters: {
      type: Boolean,
      default: true,
    },
  },
  emit: ["paginatorHandler", "updateStatus", "queryForOccurences"],
  methods: {
    getFormatedDate(date) {
      return new Date(date).toLocaleDateString('pt-pt').replaceAll('/', '.')
    },
    color(elem) {
      let str = "badge-light-";
      let color = elem === "Em Curso" ? "success" : "primary";
      return str + color;
    },
    creditBadgeColor(item) {
      if(item.creditoEuros > 0) {
        return "background-color: #efcace; color: #ee584d !important";
      }

      
    },
    getInDebtOrIncredit(item) {
      if(item.debitoEuros == 0) {
        return '-'+ round(item.creditoEuros, 2);
      }
      return round(item.debitoEuros, 2);
    },
    getRegularizedInDebtOrCredit(item) {
      if(item.creditoRegularizado > 0) {
        return '-'+ round(item.creditoRegularizado, 2);
      }
      return round(item.debitoRegularizado, 2);
    },
    getInDebtOrIncreditNotRegularized(item) {
      if(item.debitoEuros != 0) {
        return round(item.debitoEuros - item.debitoRegularizado, 2);
      }
      return '-' + (round(item.creditoEuros - item.creditoRegularizado,2));
    },
    getStatusByDate(date) {
      return new Date().setDate(new Date().getDate() -1).valueOf() < new Date(date).valueOf() ? "Por Vencer" : "Vencido";
    }, 


    
 
    paginatorHandler(page) {
      this.$emit("paginatorHandler", page);
    },
    updateStatus(status) {
      this.$emit("updateStatus", status);
    },
    queryForOccurences(searchInput) {
      this.$emit("queryForOccurences", searchInput);
    },


    orderStatusColors(date) {
      return new Date().setDate(new Date().getDate() -1).valueOf() < new Date(date).valueOf() ? "color: #56a354 !important": "color: #ee584d !important";
      
    },
  },


});
