
import router from "@/router/clean";
import { defineComponent } from "vue";

export default defineComponent({
  name: "dropdown-2",
  components: {},
  methods : {
    devolucao() {
      router.push('/occurence/dev')
    },
    incidencia() {
      router.push('/occurence/inc')
    }
  }
});
