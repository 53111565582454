
import {defineComponent} from "vue";
import Dropdown2 from "@/components/dropdown/Dropdown2.vue";
import CustomTableTopFilter from "@/components/customTables/CustomTableTopFilter.vue";
import {boolean} from "yup/lib/locale";

/* eslint-disable */
export default defineComponent({
  name: "CustomTableOrdersOccurencesDashboard",
  data() {
    return {
      rowsToShow: [""],
    };
  },
  components: {
    Dropdown2,
    CustomTableTopFilter,
  },
  props: {
    widgetClasses: String,
    tableName: String,
    list: Array,
    showStatus: {
      type: Boolean,
      default: true,
    },
    pageProp: Number,
    filterStatus: Array,
    pages: Number,
    showDownloadBtn: {
      type: Boolean,
      default: true,
    },
    showPendingQtt: {
      type: Boolean,
      default: false,
    },
    showFilters: {
      type: Boolean,
      default: true,
    },
  },
  computed: {},
  emit: ["paginatorHandler", "updateStatus", "queryForOccurences", "changeTable"],
  methods: {

    downloadpdf() {
    },

    showRow(rowId) {
      return this.rowsToShow.includes(rowId);
    },


    /*
    * ----------------DATA MANIPULATION----------------
    */

    toggleShowRowDetail(rowId) {
      const index = this.rowsToShow?.indexOf(rowId);
      if (index > -1) {
        this.rowsToShow.splice(index, 1);
        return;
      } else {
        this.rowsToShow.push(rowId);
        return;
      }
    },

    /*
    * ------------------EMITS------------------
    */

    paginatorHandler(page) {
      this.$emit("paginatorHandler", page);
    },
    updateStatus(status) {
      this.$emit("updateStatus", status);
    },
    queryForOccurences(searchInput) {
      this.$emit("queryForOccurences", searchInput);
    },

    /*
    * -------------------UTILS--------------------
    */

    getFormatedDate(date) {
      return new Date(date).toLocaleDateString('pt-pt').replaceAll('/', '.')
    },


    /*
    * -------------------STYLES-------------------
     */
    color(elem) {
      let str = "badge-light-";
      let color = elem === "Em Processamento" ? "success" : "primary";
      return str + color;
    },
    rowColor(bostamp) {
      const index = this.list!.findIndex(
          (item: any) => item.bostamp == bostamp
      );
      if (index! % 2 == 0) {
        return "background-color: #f3f2f1;";
      } else {
        return "background-color: #cfcccc;";
      }
    },

    orderStatusColors(status) {
      switch (status.toUpperCase()) {
        case "CONCLUÍDA":
          return "background-color: #dfdddd; color: #3e3739 !important; min-width:150px;";
        case "EM PROCESSAMENTO":
          return "background-color: #d9eecc; color: #3d9a01 !important; min-width:150px;";
        case "EXPEDIDA":
          return "background-color: #efcace; color: #ee584d !important; min-width:150px;";
        case "RECECIONADA":
          return "background-color: #f6d8bd; color: #f59e40 !important; min-width:150px;";
        default:
          return "background-color: #e4ebf0; color: #181c32 !important; min-width:150px;";
      }
    },
  },

});
