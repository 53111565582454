<template>
  <div :class="widgetClasses" class="card">
    <!--begin::Header-->
    <div class="card-header align-items-center border-0 pt-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="fw-bolder fs-6 mb-2 text-dark">Faturação por marca</span>
        <span class="card-label fw-bolder text-muted fs-8"><b>Top 10</b></span>
      </h3>
    </div>
    <!--end::Header-->

    <hr class="m-0" />

    <div class="card-body" style="padding: 10px !important">
      <div class="chart" ref="chartdiv"></div>
    </div>
  </div>
</template>

<script>
//AMCHARTS
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

am4core.useTheme(am4themes_animated);
am4core.addLicense("ch-custom-attribution");

export default {
  name: "pieChart.vue",
  data() {
    return {
      brandObjectValue: [],
    };
  },
  props: {
    totalByBrand: Array,
    widgetClasses: String,
  },

  methods: {
    toObject() {
      for (var [key, val] of Object.entries(this.totalByBrand)) {
        this.brandObjectValue.push({ brand: key, value: val });
      }


    },
  },

  mounted() {
    this.toObject();

    let chart = am4core.create(this.$refs.chartdiv, am4charts.PieChart);

    chart.hiddenState.properties.opacity = 0; // this creates initial fade-in

    /*
    chart.data = [
      {
        country: "Lithuania",
        value: 401,
      },
      {
        country: "Czech Republic",
        value: 300,
      },
      {
        country: "Ireland",
        value: 200,
      },
      {
        country: "Germany",
        value: 165,
      },
      {
        country: "Australia",
        value: 139,
      },
      {
        country: "Austria",
        value: 128,
      },
    ];

    */

    chart.data = this.brandObjectValue;

    chart.radius = am4core.percent(100);
    chart.innerRadius = am4core.percent(60);
    chart.startAngle = 180;
    chart.endAngle = 360;

    var series = chart.series.push(new am4charts.PieSeries());
    series.dataFields.value = "value";
    series.dataFields.category = "brand";

    series.colors.list = [
      am4core.color("#181c32"),
      am4core.color("#014b70"),
      am4core.color("#0c789b"),
      am4core.color("#15a0c2"),
      am4core.color("#18aecf"),
      am4core.color("#1fa6a3"),
      am4core.color("#229a7d"),
      am4core.color("#288c49"),
      am4core.color("#288c49"),
      am4core.color("#1c5317"),
    ];

    series.labels.template.disabled = true;
    series.ticks.template.disabled = true;

    series.slices.template.cornerRadius = 0;
    series.slices.template.innerCornerRadius = 0;
    series.slices.template.draggable = false;
    series.slices.template.inert = true;

    series.hiddenState.properties.startAngle = 90;
    series.hiddenState.properties.endAngle = 90;

    //chart.legend = new am4charts.Legend();

    this.chart = chart;
  },

  beforeUnmount() {
    if (this.chart) {
      this.chart.dispose();
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.chart {
  max-width: 100%;
  max-height: 100%;
  width: 100%;
  height: 100%;
}
</style>