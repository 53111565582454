
import { computed, defineComponent, ref } from "vue";

export default defineComponent({
  name: "TopCardNumbersInfo",
  components: {},
  props: {
    tittle: String,
    tittleIcon: String,
    info: Object,
    value: String,
    showEuroSign: {
      type: Boolean,
      default: true,
    },
    gradientStyle: String,
  },
  computed: {
    imgUrl: function () {
      return "media/" + this.info?.background;
    },
    imgBGStyle: function () {
      return (
        "background-image: url('media/" +this.info?.background +
        "'); bottom:0px; top:0px; right:0px; left:0px; position: absolute; width: 100%; overflow: hidden; background-size: 100px;" +
        "height: 100%; background-repeat: no-repeat; background-position-x: " + 
        this.info?.backgroundPositionX + "; background-position-y: " + this.info?.backgroundPositionY + ";"
      );
    },
  },
});
