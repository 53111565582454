<template>
  <!--begin::Dashboard-->
  <div style="min-width: 100%; max-width: fit-content !important">
    <!--begin::TopCardsIndicators-->
    <div class="row g-5">
      <div class="col-6 col-lg-4 col-xl-2 col-md-4">
        <TopCardTextInfo
          style="white-space: nowrap"
          :gradientStyle="gradientCard1"
          :tittle="tittleCard1"
          :values="salesmanInfo"
          :info="card1Values"
        ></TopCardTextInfo>
      </div>
      <div class="col-6 col-lg-4 col-xl-2 col-md-4 hover-cursor">
        <TopCardNumbersInfo
          @click="$router.push('/encomendas')"
          style="white-space: nowrap"
          :gradientStyle="gradientCard2"
          :tittle="tittleCard2"
          :info="card2Values"
          :showEuroSign="false"
          :value="activeOrders"
        ></TopCardNumbersInfo>
      </div>
      <div class="col-6 col-lg-4 col-xl-2 col-md-4 hover-cursor">
        <TopCardNumbersInfo
          @click.prevent="scrollToAnchorPoint('indebtTable')"
          style="white-space: nowrap"
          :gradientStyle="gradientCard3"
          :tittle="tittleCard3"
          :info="card3Values"
          :value="inDebtSum"
        ></TopCardNumbersInfo>
      </div>
      <div class="col-6 col-lg-4 col-xl-2 col-md-4 hover-cursor">
        <TopCardNumbersInfo
          @click="$router.push('/faturas')"
          style="white-space: nowrap"
          :gradientStyle="gradientCard4"
          :tittle="tittleCard4"
          :info="card4Values"
          :value="invoicesSum"
        ></TopCardNumbersInfo>
      </div>
      <div class="col-6 col-lg-4 col-xl-2 col-md-4 hover-cursor">
        <TopCardNumbersInfo
          @click.prevent="scrollToAnchorPoint('indebtTable')"
          style="white-space: nowrap"
          :gradientStyle="gradientCard5"
          :tittle="tittleCard5"
          :info="card5Values"
          :showEuroSign="false"
          :value="totalIndebtDocuments"
        ></TopCardNumbersInfo>
      </div>
      <div class="col-6 col-lg-4 col-xl-2 col-md-4 hover-cursor">
        <TopCardNumbersInfo
          @click="$router.push('/incidencias')"
          style="white-space: nowrap"
          :gradientStyle="gradientCard6"
          :tittle="tittleCard6"
          :info="card6Values"
          :showEuroSign="false"
          :value="openOccurencesSum"
        ></TopCardNumbersInfo>
      </div>
    </div>
    <!--end::TopCardsIndicators-->

    <div class="row g-5 gx-xxl-12">
      <!--
    <div class="col-12 col-lg-12 col-xl-5">
      <Widget3
        v-if="lastYearInvoicesByMonth"
        widgetClasses="card-stretch mb-5 mb-xl-8"
        :values="lastYearInvoicesByMonth"
      ></Widget3>
    </div>
  -->
      <div class="col-12 col-lg-12 col-xl-6" style="">
        <SideBySideBar
          v-if="lastAndCurrentYearInvoices"
          widgetClasses="card-stretch mb-5 mb-xl-8"
          :dataSource="lastAndCurrentYearInvoices"
        ></SideBySideBar>
      </div>

      <div class="col-12 col-sm-6 col-xl-4">
        <ProductsList
          style="white-space: nowrap; max-height: 500px"
          widget-classes="card-stretch mb-5 mb-xl-8"
          :listOfEvents="lastProductsSold"
        ></ProductsList>
      </div>

      <!--
    <div class="col-12 col-sm-6 col-xl-5">
      <PieChart
        v-if="totalByBrand"
        widgetClasses="card-stretch mb-5 mb-xl-8"
        :totalByBrand="totalByBrand"
      ></PieChart>
    </div>
    -->

      <div class="col-12 col-sm-6 col-xl-2">
        <ListWidget5
          style="max-height: 500px"
          widget-classes="card-stretch mb-5 mb-xl-8"
          :listOfEvents="lastEventsList"
          @reOrder="reOrder"
        ></ListWidget5>
      </div>
    </div>

    <div class="row g-5 gx-xxl-12 mx-1 mb-5">
      <div class="col-12 col-xl-6 pe-xl-6">
        <CustomTableInvoices
          v-if="last5Invoices"
          tableName="Últimas Faturas"
          :list="last5Invoices"
          :showFilters="false"
          :pages="2"
          @paginatorHandler="invoicesPaginatorHandler"
          widgetClasses="card-stretch mb-5 mb-xl-8"
        ></CustomTableInvoices>
      </div>

      <div class="col-12 col-xl-6 ps-xl-6">
        <CustomTableOrdersOccurences
          v-if="last5Orders"
          tableName="Últimas Encomendas"
          :list="last5Orders"
          :showFilters="false"
          :pages="2"
          @paginatorHandler="ordersPaginatorHandler"
          widgetClasses="card-stretch mb-5 mb-xl-8"
        ></CustomTableOrdersOccurences>
      </div>
    </div>

    <inDebtTable
      id="indebtTable"
      style="min-width: 100%; max-width: 100px"
      :tableName="inDebttableName"
      :list="inDebtTableList"
      :pageProp="1"
      :pages="inDebtTotalPages"
      :filterStatus="inDebtTableFilterStatus"
      :showFilters="false"
      @paginatorHandler="inDebtTablepaginatorHandler"
      @updateStatus="updateStatus"
      @queryForOccurences="queryForOccurences"
    ></inDebtTable>

    <!--end::Dashboard-->
  </div>
</template>

<script lang="js">
import { defineComponent, onMounted, computed } from "vue";
import TopCardTextInfo from "@/components/InfoCards/TopCardTextInfo.vue";
import TopCardNumbersInfo from "@/components/InfoCards/TopCardNumbersInfo.vue";
import inDebtTable from "@/components/customTables/inDebtTable.vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import Widget3 from "@/components/widgets/charts/Widget3.vue";
import ListWidget5 from "@/components/widgets/lists/Widget5.vue";
import store from "@/store";
import router from "vue-router";
import { Actions } from "@/store/enums/StoreEnums";
import { array } from "yup/lib/locale";
import PieChart from "@/components/widgets/statsistics/pieChart.vue";
import ApiService from "@/core/services/ApiService";
import SideBySideBar from "@/components/widgets/statsistics/SideBySideBar.vue";
import CustomTableInvoices from "@/components/customTables/CustomTableInvoicesDashboard.vue";
import CustomTableOrdersOccurences from "@/components/customTables/CustomTableOrdersOccurencesDashboard.vue";
import ProductsList from "@/components/widgets/lists/ProductsList.vue";


//AMCHARTS
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
am4core.useTheme(am4themes_animated);



export default defineComponent({
  name: "dashboard",
  components: {
    TopCardTextInfo,
    TopCardNumbersInfo,
    inDebtTable,
    SideBySideBar,
    CustomTableInvoices,
    CustomTableOrdersOccurences,
    ProductsList,

    //Widget3,
    ListWidget5,

    //PieChart,


  },
  data() {
    return {
      tittleCard1: "Comercial",
      tittleCard2: "Encomendas ativas",
      tittleCard3: "Por regularizar",
      tittleCard4: "Faturação Ano Atual",
      tittleCard5: "Documentos vencidos",
      tittleCard6: "Incidências abertas",



      //GRADIENT FOR EACH CARD
      gradientCard1:
        "background: rgb(40,13,12); background: linear-gradient(90deg, rgba(40,13,12) 0%, rgba(70,13,14,1) 100%);",
      gradientCard2:
        "background: rgb(70,13,14,1);background: linear-gradient(90deg, rgba(70,13,14,1) 0%, rgba122,12,17,1) 100%);",
      gradientCard3:
        "background: rgba(122,12,17,1);background: linear-gradient(90deg, rgba(122,12,17,1) 0%, rgba(151,12,19,1) 100%);",
      gradientCard4:
        "background: rgba(151,12,19,1); background: linear-gradient(90deg, rgba(151,12,19,1) 0%, rgba(174,12,20,1) 100%",
      gradientCard5:
        "background: rgba(174,12,20,1); background: linear-gradient(90deg, rgba(174,12,20,1) 0%, rgba(193,12,21,1) 100%;",
      gradientCard6:
        "background: rgba(193,12,21,1); background: linear-gradient(90deg,  rgba(193,12,21,1) 0%, rgba(227,11,23,1) 100%);",


       /*
       background: rgb(40,13,12);
        background: linear-gradient(90deg, rgba(40,13,12,1) 0%, 
        rgba(70,13,14,1) 16%, rgba(122,12,17,1) 32%, 
        rgba(151,12,19,1) 50%, rgba(174,12,20,1) 66%, 
        rgba(193,12,21,1) 83%, rgba(227,11,23,1) 100%);

      */

      
      //BACKFROUND PROPERTIES FOR EACH CARD
        card1Values: {
        background: "dash_icons/card_delegate.svg",
        backgroundPositionX: "125%",
        backgroundPositionY: "122%",

      },
      card2Values: {
        background: "dash_icons/card_orders.svg",
        backgroundPositionX: "110%",
        backgroundPositionY: "122%",

      },
      card3Values: {
        background: "dash_icons/card_debts.svg",
        backgroundPositionX: "110%  ",
        backgroundPositionY: "110%",

      },
      card4Values: {
        background: "dash_icons/card_invoicing.svg",
        backgroundPositionX: "110%",
        backgroundPositionY: "110%",

      },
      card5Values: {
        background: "dash_icons/card_savings.svg",
        backgroundPositionX: "110%",
        backgroundPositionY: "110%",

      },
      card6Values: {
        background: "dash_icons/card_incidence.svg",
        backgroundPositionX: "110%",
        backgroundPositionY: "115%",

      },


      //DATA RELATED TO INDEBT TABLE
      inDebttableName: "Pendentes",
      inDebtTablePage: 1,
      inDebtTableList: [],
      inDebtTableItemsPerPage: 5,
      inDebtTableStatusToFilterFor: "",
      inDebtTableSearchToFilterFor: "",
      inDebtTotalPages: 0,
      totalIndebtDocuments: "0",

      //chart data
      lastAndCurrentYearInvoices: [],

      last10Invoices : [],
      last5Invoices: [],
      last5Orders: [],

      last20Orders: [],
      last20Invoices: [],
      last20Occurences: [],

      pageTableInvoices: 1,
      pageTableOrders: 1,

    };
  },
  watch: {
    inDebtQtt() {
      this.loadInDebt()
    },
    currentAuthUser() {
      //if client change or on refresh
      store.dispatch(Actions.LOAD_DASH_INVOICES);
    this.loadInDebt(1);

    this.loadInvoicesChartData();

    this.load5LastInvoices();
    this.load20LastOrders();
    this.load20LastInvoices();
    this.load20LastOccurences();

    this.loadTotalInDebtValue();
    }
  },
  computed: {
    //TOP INDICATORS
    invoicesSum() {

      if(store.getters.getDashboardInvoices !== undefined && store.getters.getDashboardInvoices.fat12Meses !== undefined){
        return store.getters.getDashboardInvoices.fat12Meses.toLocaleString('pt-pt');
      }
      return "0"
    },
    lastYearInvoicesByMonth(){
      return store.getters.getDashboardInvoices.lastYearInvoicesSumByMonth;
    },
    totalByBrand() {

      return store.getters.getTotalByBrand;
    },
    salesmanInfo(){
      let userMe = store.getters.currentUser;
      return [
        { image: "dash_icons/user_icon.svg", texto: userMe.vendedor.nome },
        {
          image: "dash_icons/email_icon.svg",
          texto: userMe.vendedor.email,
        },
        {
          image: "dash_icons/phone_icon.svg",
          texto: userMe.vendedor.tel1,
        },
      ];
    },
    activeOrders() {
      return store.getters.getDashboardInvoices == undefined ? "0" : store.getters.getDashboardInvoices.encAbertas;
    
    },
    inDebtSum() {
      if(store.getters.getDashboardInvoices !== undefined && store.getters.getDashboardInvoices.porRegularizar != undefined)
        return store.getters.getDashboardInvoices.porRegularizar.toLocaleString('pt-pt');

      return "0";  
    },
    openOccurencesSum() {
      return store.getters.getDashboardInvoices == undefined ? "0" : store.getters.getDashboardInvoices.incAbertas;
    },
    lastEventsList() {
      if(!this.last20Invoices.length && !this.last20Orders.length && !this.last20Occurences.length){
        return [];
      }

      //get most recent 20 documents from last 20 invoices, orders and occurences
      let lastEvents = [];
      
      //trasnform dataFatura by dataDossier on last20Invoices
      this.last20Invoices.forEach(element => {
        element.dataDossier = element.dataFatura;
      });

      //join everything in one array
      lastEvents = this.last20Invoices.concat(this.last20Orders).concat(this.last20Occurences);

      //sort by date
      lastEvents.sort(function(a, b) {
        return new Date(b.dataDossier) - new Date(a.dataDossier);
      });


      //get 20 first
      lastEvents = lastEvents.slice(0, 20);

      //sey name of documents, if has stampFatura object name = Fatura
      //if has bostamp object and numSerie = 1, name = Encomenda
      //if has bostamp object and numSerie = 38, name = Incidencia
      lastEvents.forEach(element => {
        if (element.stampFatura) {
          element.docName = element.nomeDocumento
          element.docNum = element.numFatura
        } else if (element.bostamp) {
          element.docNum = element.numDossier
          if (element.numSerie == 1) {
            element.docName = "Encomenda";
          } else if (element.numSerie == 38) {
            element.docName = "Incidência";
          }
        }
      });

      return lastEvents
      
    },
    //Last products sold
    lastProductsSold() {

      if(!this.last20Orders.length){
        return [];
      }

      let products = [];

      //get products from each order line
      this.last20Orders.forEach(element => {
        element.dossierLinha.forEach(line => {
          products.push(line.produtos);
        });
      });

      //remove duplicated products filter by design
      var uniq = {};
      var filtered = products.filter(obj => obj && !uniq[obj.design] && (uniq[obj.design] = true));

      return filtered;

    }, 


    //get distinct status of inDebtTable
    inDebtTableFilterStatus() {
      //IF INDEBT ALREADY LOADED
      if(store.getters.getInDebt && store.getters.getInDebt.length)
      {
        return store.getters.getInDebt.reduce((accumulator, current) => {
          if (!accumulator.includes(current["status"])) {
          accumulator.push(current["status"]);
        }
        return accumulator;
      }, [])
      }
      return []

    },

    inDebtQtt() {
      if(!store.getters.getInDebt)
        return 0;
      return store.getters.getInDebt.length;
    },
    inListQtt() {
      return this.inDebtTableData.length;
    
    },
    inDebtTablePages() {
      return Math.ceil(this.inListQtt / this.inDebtTableItemsPerPage);
    },
    currentAuthUser() {
      return store.getters.currentUser;
    },
  },
  methods: {
    ordersPaginatorHandler(page) {
      this.pageTableOrders = page;
      this.last5Orders = this.last20Orders.slice(
        (page - 1) * this.inDebtTableItemsPerPage,
        page * this.inDebtTableItemsPerPage
      );
    },
    invoicesPaginatorHandler(page) {
      this.pageTableInvoices = page;
      this.last5Invoices = this.last10Invoices.slice(
        (page - 1) * this.inDebtTableItemsPerPage,
        page * this.inDebtTableItemsPerPage
      );
    },

    scrollToAnchorPoint(refName) {
      let element = document.querySelector(`#${refName}`);
      element.scrollIntoView({ behavior: "smooth" });     
    },

    reOrder() {
      //not needed anymore
    },

    //METHODS RELATED TO INDEBT TABLE
    inDebtTablepaginatorHandler(page) {
      this.loadInDebt(page);
    },
    loadTotalInDebtValue() {
      

      ApiService.get("/Cliente/NaoRegularizados", `?pageNumber=1&pageSize=9999999`)
        .then((response) => {
          //filter by date < today
          this.totalIndebtDocuments = response.data.data.filter(element => {
            return new Date(element.dataVencimento) < new Date().setDate(new Date().getDate() -1);
          }).length;


        })
        .catch((error) => {
          console.log(error);
        });
    },

    loadInDebt(page) {
      this.inDebtTablePage = page;

      ApiService.get("/Cliente/NaoRegularizados", `?pageNumber=${page}&pageSize=${this.inDebtTableItemsPerPage}`)
        .then((response) => {
          this.inDebtTableList = response.data.data;
          this.inDebtTotalPages = response.data.totalPages;

        })
        .catch((error) => {
          console.log(error);
        });

    },
    workChartData(data) {
      if(data[0].year > data[1].year)
        data.reverse();

      
      this.lastAndCurrentYearInvoices = [
        {
          month: 'JAN',
          year1: data[0].jan,
          year2: data[1].jan
        },
        {
          month: 'FEV',
          year1: data[0].fev,
          year2: data[1].fev
        },
        {
          month: 'MAR',
          year1: data[0].mar,
          year2: data[1].mar
        },
        {
          month: 'ABR',
          year1: data[0].abr,
          year2: data[1].abr
        },
        {
          month: 'MAI',
          year1: data[0].mai,
          year2: data[1].mai
        },
        {
          month: 'JUN',
          year1: data[0].jun,
          year2: data[1].jun
        },
        {
          month: 'JUL',
          year1: data[0].jul,
          year2: data[1].jul
        },
        {
          month: 'AGO',
          year1: data[0].ago,
          year2: data[1].ago
        },
        {
          month: 'SET',
          year1: data[0].set,
          year2: data[1].set
        },
        {
          month: 'OUT',
          year1: data[0].outu,
          year2: data[1].outu
        },
        {
          month: 'NOV',
          year1: data[0].nov,
          year2: data[1].nov
        },
        {
          month: 'DEZ',
          year1: data[0].dez,
          year2: data[1].dez
        },

      ]

    },
    loadInvoicesChartData() {
      ApiService.get("Cliente/FatAnual")
        .then((response) => {          
          this.workChartData(response.data.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    load5LastInvoices(){   
      let queryString = `?pageNumber=1&pageSize=10&numCliente=${store.getters.currentUser.numcl}&nomeDocumento=Factura`;
      
       ApiService.get("cliente/faturas", queryString)
      .then((response) => {
        this.last10Invoices = response.data.data;
        this.last5Invoices  = this.last10Invoices.slice(0,5);
       })
        .catch((error) => {
          console.log(error);
        });


    },
    load20LastOrders(){
       let queryString = `?pageNumber=1&pageSize=20&numCliente=${store.getters.currentUser.numcl}&NumSerie=1`;
      ApiService.get("/orders", queryString)
        .then((response) => {
          //get first 5 orders
          this.last5Orders = response.data.data.slice(0,5);
          this.last20Orders = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });


    },
    load20LastInvoices() {
      let queryString = `?pageNumber=1&pageSize=20&numCliente=${store.getters.currentUser.numcl}`;
      ApiService.get("cliente/faturas", queryString)
        .then((response) => {
          this.last20Invoices = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });

    },

    load20LastOccurences(){
      let queryString = `?pageNumber=1&pageSize=20&numCliente=${store.getters.currentUser.numcl}&NumSerie=38`;
      ApiService.get("orders", queryString)
        .then((response) => {
          this.last20Occurences = response.data.data          
        })
        .catch((error) => {
          console.log(error);
        });
    },

    filterByStatusAndDocNumber() {
      
      //IF INDEBT ALREADY LOADED
      if(store.getters.getInDebt && store.getters.getInDebt.length)
      {
      let inDebtList = store.getters.getInDebt.filter(
        (p) =>
          (p.status + "").includes(this.inDebtTableStatusToFilterFor) &&
          (p.documentNumber + "").includes(this.inDebtTableSearchToFilterFor)
      );

      return inDebtList;
      }

      return []
    },

    //filters by status, update pages, and list to show
    updateStatus(status) {
      this.inDebtTableStatusToFilterFor = status;
      this.inDebtTablepaginatorHandler(1);
    },
    queryForOccurences(searchInput) {
      this.inDebtTableSearchToFilterFor = searchInput;

      this.inDebtTablepaginatorHandler(1);
    },
  },
  mounted() {
    setCurrentPageTitle("Portal Cliente | Dashboard principal");

    store.dispatch(Actions.LOAD_DASH_INVOICES);
    this.loadInDebt(1);

    this.loadInvoicesChartData();

    this.load5LastInvoices();
    this.load20LastOrders();
    this.load20LastInvoices();
    this.load20LastOccurences();

    this.loadTotalInDebtValue();

  /*

    //if occurences not loaded yet  
    if(!store.getters.getOccurencesList)
    {  
      
      store.dispatch(Actions.LOAD_OCCURENCES);
    }

    //if invoices not loaded yet  
    if(!store.getters.getInvoices || Object.keys(store.getters.getInvoices).length === 0) {

      //LOAD INVOICES
      store.dispatch(Actions.LOAD_INVOICES);
    }  
      
    //if orders not loaded yet  
    if(!store.getters.getOrders || !store.getters.getOrders.length)
    {      
      store.dispatch(Actions.LOAD_ORDERS);
    }
    */
   

  },

});
</script>

<style scoped>
.row-flex {
  display: flex;
  flex-wrap: wrap;
}

.hover-cursor {
  cursor: pointer;
}
</style>

