<template>
<div :class="widgetClasses" class="card">
  <DxChart
    id="chart"
    :data-source="dataSource"
    title="Faturação- Ano atual vs ano anterior"
    @pointClick="onPointClick"
  >
    <DxCommonSeriesSettings
      argument-field="month"
      type="bar"
      hover-mode="onlyPoint"
      selection-mode="onlyPoint"
    >
      <DxLabel :visible="false">
        <DxFormat
          :precision="0"
          type="fixedPoint"
        />
      </DxLabel>
    </DxCommonSeriesSettings>
    <DxSeries
      value-field="year1"
      color="#f8d6b0"
      cornerRadius=5
      :border=
        "{
          visible: true,
          color: '#f7c098',
        }"
      
      :name="(new Date().getFullYear() - 1) + ''"
    />
    <DxSeries
      value-field="year2"
      color="#debaac"
      cornerRadius=5
      :border=
        "{
          visible: true,
          color: '#d7c7c6',
        }"
      :name="new Date().getFullYear()+''"
    />
    <DxLegend
      vertical-alignment="bottom"
      horizontal-alignment="center"
    />
    <DxExport :enabled="true"/>
    <DxTooltip
      :enabled="true"
      :customize-tooltip="customizeTooltip"
      location="edge"
    />
  </DxChart>
</div>
</template>
<script>
import {
  DxChart,
  DxSeries,
  DxCommonSeriesSettings,
  DxLabel,
  DxFormat,
  DxLegend,
  DxExport,
  DxTooltip,

} from 'devextreme-vue/chart';

export default {
    name: 'SideBySideBar',
  components: {
    DxChart,
    DxSeries,
    DxCommonSeriesSettings,
    DxLabel,
    DxTooltip,
    DxFormat,
    DxLegend,
    DxExport,
  },
  data() {
    return {
     
    };
  },
  props: {
    dataSource: Array,
    widgetClasses: String,
  },
  methods: {
    customizeTooltip(pointInfo) {
      return {
        text: `Ano: ${pointInfo.seriesName} Valor: ${ Number(pointInfo.valueText).toLocaleString('pt-pt')}€`,
      };
    },
    onPointClick({ target }) {
      target.select();
    },
  },
  
};
</script>
<style>
#chart {
  height: 440px;
}
</style>
